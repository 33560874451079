import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HttpHeaderInterceptor } from "./shared/interceptor/http-header.interceptor";
import { FailedComponent } from "./failed/failed.component";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from "@azure/msal-browser";
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ModalService, PrimerModule } from "@primer/angular-components";
import { ScatterComponent } from "./scatter/scatter.component";
import { HeaderComponent } from "./header/header.component";
import { PigmentInfoCardComponent } from './pigment-info-card/pigment-info-card.component';
import { AdjustmentCardHeaderComponent } from './adjustment-card-header/adjustment-card-header.component';
import { GraphComponent } from './graph/graph.component';
import { SimulatorDataService } from "src/simulator-data-service/simulator-data.service";
import { FooterComponent } from './footer/footer.component';
import { LoadingService } from "./shared/loading-service/loading.service";
import { LoadingInterceptor } from "./shared/interceptor/loading.interceptor";
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalComponent } from './modal/modal.component';
import { AnnouncementModalComponent } from "./announcement-modal/announcement-modal.component";
import { DatePipe } from "@angular/common";
import { ToleranceTableComponent } from './tolerance-table/tolerance-table.component';
import { TooltipModule } from './tooltip/tooltip.module';
import { ClickableTooltipModule } from './clickable-tooltip/clickable-tooltip.module';
import { GraphListComponent } from './graph-list/graph-list.component';
import { GraphTooltipComponent } from './graph/graph-tooltip/graph-tooltip.component';
import { GraphSettingsComponent } from './graph-settings/graph-settings.component';
import { FailedLoadingComponent } from './failed-loading/failed-loading.component';
import { ErrorHandlingService } from "./shared/error-handling-service/error-handling.service";
import { GraphSettingsService } from "./shared/graph-settings-service/graph-settings.service";
import { GuidancePillComponent } from './guidance-pill/guidance-pill.component';
import { GuidanceAnnouncementModalComponent } from './guidance-announcement-modal/guidance-announcement-modal.component';
import { TooltipResizeRequestService } from "./shared/tooltip-resize-request-service/tooltip-resize-request.service";
import { NotificationFrameworkModule, ToastMessagingService } from '@app-dev/notification-framework';
import { OnGoingDevelopmentModalComponent } from './on-going-development-modal/on-going-development-modal.component';
import AppConfigurationService from "./shared/app-configuration-service/app-configuration.service";
import { ConfigsLoaderService } from "./shared/config-loader-service/config-loader-service";
import { FaqModalComponent } from "./faq-modal/faq-modal.component";
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { MlErrorInterceptor } from "./shared/interceptor/ml-error.interceptor";
import { ReleaseNoteModalComponent } from './release-note-modal/release-note-modal.component';
import { ModalQueueService } from "./shared/modal-queue-service/ModalQueueService";
import { ButtonModule } from 'primeng/button';
import { HitRecommendationsComponent } from "./hit-recommendations/hit-recommendations.component";
import { RadioButtonModule } from 'primeng/radiobutton';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(config: ConfigsLoaderService): IPublicClientApplication {

  return new PublicClientApplication({
    auth: {
      clientId: config.appAuthClientId,
      authority: config.appAuthAuthority,
      redirectUri: config.appAuthRedicrectUri,
      postLogoutRedirectUri: config.appPostLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(config: ConfigsLoaderService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("/ppg/aiml/colorai", [
    config.appAuthScope,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(config: ConfigsLoaderService): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [config.appAuthScope],
    },
    loginFailedRoute: "/login-failed",
  };
}

// Factory function required during AOT compilation
export function httpTranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FailedComponent,
    LandingPageComponent,
    ScatterComponent,
    GraphComponent,
    AdjustmentCardHeaderComponent,
    PigmentInfoCardComponent,
    HeaderComponent,
    FooterComponent,
    LoadingPageComponent,
    ModalComponent,
    AnnouncementModalComponent,
    ToleranceTableComponent,
    GraphListComponent,
    GraphTooltipComponent,
    GraphSettingsComponent,
    FailedLoadingComponent,
    GuidancePillComponent,
    GuidanceAnnouncementModalComponent,
    OnGoingDevelopmentModalComponent,
    FaqModalComponent,
    ReleaseNoteModalComponent,
    HitRecommendationsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    ReactiveFormsModule,
    FormsModule,
    PrimerModule,
    NgxSliderModule,
    NgxSpinnerModule,
    TooltipModule,
    NotificationFrameworkModule,
    ClickableTooltipModule,
    DropdownModule,
    AccordionModule,
    ButtonModule,
    RadioButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MlErrorInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ConfigsLoaderService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [ConfigsLoaderService]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [ConfigsLoaderService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlingService
    },
    HttpHeaderInterceptor,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    SimulatorDataService,
    ErrorHandlingService,
    GraphSettingsService,
    HttpClient,
    LoadingService,
    ModalService,
    TooltipResizeRequestService,
    ToastMessagingService,
    DatePipe,
    AppConfigurationService,
    ConfigsLoaderService,
    ModalQueueService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
